.investBox {
  /* width: 1200px;
  height: 1006px; */
  /* background-color: rgba(184, 169, 169, 0.1); */
  /* background-color: rgba(235, 12, 12, 0.1); */
  background: rgba(0, 0, 0, 0.1);
  /* background: green; */
  backdrop-filter: blur(49.3333px);
}

.image {
  width: 14px;
  height: 14px;
}

.inputLabel__jj {
  color: white !important;
}

.b-contain *,
.b-contain *::before,
.b-contain *::after {
  box-sizing: content-box !important;
}

.b-contain input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.b-contain span {
  line-height: 1.39;
  font-size: 14px;
  font-family: inherit;
}

@media (max-width: 455px) {
  .b-contain span {
    line-height: 1.39;
    font-size: 10px;
    font-family: inherit;
  }
}

.b-contain {
  display: table;
  position: relative;
  padding-left: 3rem;
  cursor: pointer;
  margin-bottom: 0.5rem;
}

.b-contain input[type="checkbox"] ~ .b-input {
  position: absolute;
  top: 0;
  left: 0;
  height: 1.25rem;
  width: 1.25rem;
  background: rgb(5, 5, 5);
  transition: background 250ms;
  border: 2px solid rgba(235, 238, 240, 1);
  border-radius: 0.104rem;
}

.b-contain input[type="checkbox"] ~ .b-input::after {
  content: "";
  position: absolute;
  display: none;
  left: 0.45rem;
  top: 0.18rem;
  width: 0.25rem;
  height: 0.6rem;
  border: solid rgba(230, 218, 218, 1);
  border-width: 0 2px 2px 0;
  transition: background 250ms;
  transform: rotate(45deg);
}

.b-contain input:disabled ~ .b-input::after {
  border-color: rgba(135, 149, 161, 1);
}

.b-contain input:checked ~ .b-input::after {
  display: block;
}

.b-contain input:checked:focus ~ .b-input,
.b-contain:hover input:not([disabled]):checked ~ .b-input {
  background: rgb(11, 12, 12);
  border-color: rgb(16, 17, 17);
}

.upload-container {
  position: relative;
}
/* .upload-container input { */
/* background: #050505; */
/* outline: 2px dashed #92b0b3; */
/* outline-offset: -10px; */
/* padding: 30px 0px 50px 330px; */
/* text-align: center !important; */
/* width: 780px; */
/* height: 52px; */
/* padding-top: 15px; */
/* padding-right: 150px; */
/* background-color: red; */
/* opacity: 0.2; */
/* } */

.upload-container input:hover {
  background: #ddd;
}

.upload-btn {
  margin-left: 300px;
  padding: 7px 20px;
}
.download {
  color: var(--main-color);
  font-size: 14px;
}
.nda {
  color: white;
}

@media (max-width: 600px) {
  .download {
    font-size: 12px;
  }
}
@media (max-width: 490px) {
  .download {
    font-size: 10px;
  }
}
@media (max-width: 420px) {
  .download {
    font-size: 9px;
  }
}
