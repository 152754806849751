.ImageLargeText .desc {
  color: white;
  font-size: 13px;
  opacity: 50%;
  margin-bottom: 20px;
}
.ImageLargeText img {
  margin-bottom: 30px;
}
.ImageLargeText .white {
  color: white;
  margin-bottom: 20px;
}
.ImageLargeText .green {
  color: var(--main-color);
}
.ImageLargeText .white,
.ImageLargeText .green {
  color: 22px;
}
.more-news {
  color: white;
  font-size: 15px;
  opacity: 50%;
  margin-top: 80px;
  margin-bottom: 35px;
}
