.messageceo {
  margin-top: 100px;
}
.messageceo .title1 {
  color: white;
  margin-bottom: 24px;
}
.messageceo .title2 {
  color: var(--main-color);
}
.messageceo .title1,
.messageceo .title2 {
  font-size: 22px;
  font-weight: 500;
}
.messageceo .desc1,
.messageceo .desc2 {
  font-size: 14px;
  font-weight: 400;
  color: white;
  opacity: 50%;
}
.messageceo .desc1 {
  margin-bottom: 24px;
}
.messageceo .desc2 {
  margin-bottom: 32px;
}
.messageceo .ceo-name {
  color: var(--main-color);
}

@media (max-width: 850px) and (min-width: 600px) {
  .messageceo .title1 {
    margin-bottom: 6px;
  }
  .messageceo .title1,
  .messageceo .title2 {
    font-size: 20px;
    font-weight: 500;
  }
  .messageceo .desc1,
  .messageceo .desc2 {
    font-size: 13px;
  }
  .messageceo .desc1 {
    margin-bottom: 6px;
  }
  .messageceo .desc2,
  .messageceo .desc2 {
    min-width: 280px;
  }
  .messageceo .desc2 {
    margin-bottom: 6px;
  }
}

@media (max-width: 600px) {
  .messageceo {
    text-align: center;
  }
}
