.card-cont-bus {
  width: 100%;
  background-color: #1a1a1a;
}

.card-cont-bus .card-title-bus {
  bottom: 50px;
  color: white;
  font-weight: 500;
  font-size: 22px;
}
.content-container-bus {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.card-img-bus {
  width: 52px;
}
.content-container-bus button {
  visibility: hidden;
}

.card-cont-bus:hover button {
  visibility: visible;
}
.card-cont-bus .learn-more {
  opacity: 50%;
}
