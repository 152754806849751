.ContactUsHome {
  position: relative;
}
.ContactUsHome img {
  width: 100%;
}
.ContactUsHome .news-overlay {
  position: absolute;
  top: 33%;
  left: 6%;
}

.ContactUsHome .pretitle {
  color: white;
  font-size: 24px;
  font-weight: 350;
  opacity: 50%;
  margin-bottom: 20px;
}
.ContactUsHome .title2 {
  color: white;
  font-size: 58px;
  font-weight: 450;
}
.ContactUsHome .title2 {
  color: var(--main-color);
  margin-bottom: 20px;
}

@media (max-width: 1030px) {
  .ContactUsHome .title2 {
    font-size: 48px;
  }
}

@media (max-width: 900px) {
  .ContactUsHome .title2 {
    font-size: 38px;
  }
}
@media (max-width: 790px) {
  .ContactUsHome .news-overlay {
    top: 30%;
  }
  .ContactUsHome .pretitle {
    margin-bottom: 13px;
    font-size: 14px;
  }

  .ContactUsHome .title2 {
    font-size: 30px;
  }
  .ContactUsHome .title2 {
    margin-bottom: 13px;
  }
}

@media (max-width: 600px) {
  .ContactUsHome .news-overlay {
    top: 27%;
  }
  .ContactUsHome .pretitle {
    margin-bottom: 13px;
  }

  .ContactUsHome .title2 {
    font-size: 25px;
  }
  .ContactUsHome .title2 {
    margin-bottom: 13px;
  }
}

@media (max-width: 500px) {
  .ContactUsHome .news-overlay {
    top: 30%;
  }
  .ContactUsHome .pretitle {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .ContactUsHome .title2 {
    font-size: 20px;
  }
  .ContactUsHome .title2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .ContactUsHome .news-overlay {
    top: 35%;
  }
  .ContactUsHome .pretitle {
    margin-bottom: 3px;
    font-size: 11px;
  }
  .ContactUsHome .title2 {
    font-size: 18px;
  }
  .ContactUsHome .title2 {
    margin-bottom: 8px;
  }
}

@media (max-width: 350px) {
  .ContactUsHome .pretitle {
    font-size: 10px;
    margin-bottom: 8px;
  }

  .ContactUsHome .title2 {
    font-size: 16px;
  }
}

@media (max-width: 300px) {
  .ContactUsHome .news-overlay {
    top: 35%;
  }
  .ContactUsHome .pretitle {
    font-size: 9px;
  }

  .ContactUsHome .title2 {
    font-size: 12px;
  }
  .ContactUsHome .title2 {
    margin-bottom: 5px;
  }
}
