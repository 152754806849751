.card-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  /* background-color: #1a1a1a; */
}
.card-cont .card-title {
  bottom: 50px;
  color: white;
  font-weight: 500;
  font-size: 22px;
}
