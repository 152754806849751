.NewsCenterHome {
  position: relative;
}
.NewsCenterHome img {
  width: 100%;
}
.NewsCenterHome .news-overlay {
  position: absolute;
  top: 33%;
  left: 6%;
}

.NewsCenterHome .pretitle {
  color: white;
  font-size: 20px;
  font-weight: 300;
  opacity: 50%;
  margin-bottom: 20px;
}
.news-overlay .title1,
.news-overlay .title2 {
  color: white;
  font-size: 34px;
  font-weight: 400;
}
.news-overlay .title2 {
  color: var(--main-color);
  margin-bottom: 20px;
}
.news-overlay .date {
  color: white;
  opacity: 50%;
}
@media (max-width: 1030px) {
  .news-overlay .title1,
  .news-overlay .title2 {
    font-size: 30px;
  }
}

@media (max-width: 900px) {
  .news-overlay .title1,
  .news-overlay .title2 {
    font-size: 26px;
  }
}
@media (max-width: 790px) {
  .NewsCenterHome .news-overlay {
    top: 25%;
  }
  .news-overlay .pretitle {
    margin-bottom: 13px;
    font-size: 14px;
  }
  .news-overlay .title1,
  .news-overlay .title2 {
    font-size: 22px;
  }
  .news-overlay .title2 {
    margin-bottom: 13px;
  }
}

@media (max-width: 600px) {
  .NewsCenterHome .news-overlay {
    top: 22%;
  }
  .news-overlay .pretitle {
    margin-bottom: 13px;
  }
  .news-overlay .title1,
  .news-overlay .title2 {
    font-size: 20px;
  }
  .news-overlay .title2 {
    margin-bottom: 13px;
  }
}

@media (max-width: 500px) {
  .NewsCenterHome .news-overlay {
    top: 25%;
  }
  .news-overlay .pretitle {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .news-overlay .title1,
  .news-overlay .title2 {
    font-size: 16px;
  }
  .news-overlay .title2 {
    margin-bottom: 10px;
  }
}

@media (max-width: 400px) {
  .NewsCenterHome .news-overlay {
    top: 27%;
  }
  .news-overlay .pretitle {
    margin-bottom: 8px;
    font-size: 11px;
  }
  .news-overlay .date {
    font-size: 11px;
  }
  .news-overlay .title1,
  .news-overlay .title2 {
    font-size: 14px;
  }
  .news-overlay .title2 {
    margin-bottom: 8px;
  }
}

@media (max-width: 350px) {
  .NewsCenterHome .news-overlay {
    top: 30%;
  }
  .news-overlay .pretitle {
    font-size: 10px;
  }
  .news-overlay .title1,
  .news-overlay .title2 {
    font-size: 12.5px;
  }
}

@media (max-width: 300px) {
  .NewsCenterHome .news-overlay {
    top: 30%;
  }
  .news-overlay .pretitle {
    font-size: 9px;
  }
  .news-overlay .title1,
  .news-overlay .title2 {
    font-size: 11px;
  }
  .news-overlay .title2 {
    margin-bottom: 5px;
  }
}
