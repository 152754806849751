.ContactUsInfo {
  backdrop-filter: blur(30px);
  /* margin-top: -63px !important; */
  padding: 20px;
  /* height: 580px; */
}

@media (min-width: 600px) {
  .ContactUsInfo {
    height: 580px;
  }
}

.title {
  color: white;
  font-weight: 400;
}
.ContactUsInfo .location {
  margin-bottom: 20px;
}
.ContactUsInfo .location .t1 {
  font-size: 18px;
}
.ContactUsInfo .location .t2,
.ContactUsInfo .location .t3 {
  font-size: 12px;
}

.ContactUsInfo .callAndEmail {
  display: flex !important;
  justify-content: space-between;
  margin-top: 20px;
}
.ContactUsInfo .email .t1 {
  font-size: 16px;
}
.ContactUsInfo .email .t2 {
  font-size: 13px;
}
.ContactUsInfo .call .t1 {
  font-size: 16px;
}
.ContactUsInfo .call .t2 {
  font-size: 13px;
}

.ContactUsInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 1000px) {
  .ContactUsInfo .email .t1,
  .ContactUsInfo .call .t1,
  .ContactUsInfo .call .t2 {
    font-size: 12px;
  }
}
@media (max-width: 820px) {
  .ContactUsInfo .email .t1,
  .ContactUsInfo .email .t2,
  .ContactUsInfo .call .t1,
  .ContactUsInfo .call .t2,
  .ContactUsInfo .location .t1,
  .ContactUsInfo .location .t2,
  .ContactUsInfo .location .t3 {
    font-size: 10px;
  }
}
@media (max-width: 700px) {
  .ContactUsInfo .email .t1,
  .ContactUsInfo .email .t2,
  .ContactUsInfo .call .t1,
  .ContactUsInfo .call .t2,
  .ContactUsInfo .location .t1,
  .ContactUsInfo .location .t2,
  .ContactUsInfo .location .t3 {
    font-size: 9px;
  }
}
@media (min-width: 600px) and (max-width: 650px) {
  .ContactUsInfo .email .t1,
  .ContactUsInfo .email .t2,
  .ContactUsInfo .call .t1,
  .ContactUsInfo .call .t2,
  .ContactUsInfo .location .t1,
  .ContactUsInfo .location .t2,
  .ContactUsInfo .location .t3 {
    font-size: 8px;
  }
}
