.AboutAVH {
  position: relative;
}
.AboutAVH img {
  width: 100%;
}
.AboutAVH .about-overlay {
  color: white;
  position: absolute;
  top: 25%;
  left: 5%;
  width: 466px;
  opacity: 70%;
  font-size: 15px;
  font-weight: 400;
  line-height: 25.6px;
}
.AboutAVH span {
  color: var(--main-color);
}
.AboutAVH .pretitle {
  font-size: 20px;
  font-weight: 300;
  opacity: 50%;
  margin-bottom: 20px;
}
.about-overlay .title1,
.about-overlay .title2 {
  font-size: 50px;
  font-weight: 500;
  line-height: 110%;
}
.about-overlay .title2 {
  color: var(--main-color);
  margin-bottom: 20px;
}

@media (max-width: 790px) {
  .AboutAVH .about-overlay {
    top: 25%;
  }
  .about-overlay .pretitle {
    margin-bottom: 13px;
  }
  .about-overlay .title1,
  .about-overlay .title2 {
    font-size: 25px;
  }
  .about-overlay .title2 {
    margin-bottom: 13px;
  }
  .about-overlay .desc {
    font-size: 14px;
    line-height: 23px;
  }
}

@media (max-width: 500px) {
  .AboutAVH .about-overlay {
    top: 25%;
  }
  .about-overlay .pretitle {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .about-overlay .title1,
  .about-overlay .title2 {
    font-size: 18px;
  }
  .about-overlay .title2 {
    margin-bottom: 10px;
  }
  .about-overlay .desc {
    font-size: 12px;
    max-width: 350px;
    line-height: 18.5px;
  }
}

@media (max-width: 400px) {
  .AboutAVH .about-overlay {
    top: 19%;
  }
  .about-overlay .pretitle {
    margin-bottom: 0px;
    font-size: 12px;
  }
  .about-overlay .title1,
  .about-overlay .title2 {
    font-size: 15px;
  }
  .about-overlay .title2 {
    margin-bottom: 5px;
  }
  .about-overlay .desc {
    font-size: 11px;
    max-width: 250px;
    line-height: 15px;
  }
}

@media (max-width: 350px) {
  .AboutAVH .about-overlay {
    top: 19%;
  }
  .about-overlay .pretitle {
    margin-bottom: 0px;
    font-size: 11px;
  }
  .about-overlay .title1,
  .about-overlay .title2 {
    font-size: 14px;
  }
  .about-overlay .title2 {
    margin-bottom: 5px;
  }
  .about-overlay .desc {
    font-size: 10px;
    max-width: 250px;
    line-height: 14px;
  }
}
