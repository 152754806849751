.largeimageTextbelow .title1 {
  color: white;
  font-size: 22px;
}
.largeimageTextbelow .title2 {
  color: var(--main-color);
  font-size: 22px;
  margin-bottom: 10px;
}
.largeimageTextbelow .desc1,
.largeimageTextbelow .desc2 {
  color: white;
  opacity: 50%;
  font-size: 14px;
  font-weight: 250;
}
.largeimageTextbelow .desc2 {
  margin-top: 20px;
}
.largeimageTextbelow .img-cont img {
  margin-bottom: 10px;
}
.largeimageTextbelow .learn-more {
  font-size: 17px;
  opacity: 80%;
  margin: 0px !important;
  margin-bottom: 10px;
}
@media (min-width: 600px) {
  .largeimageTextbelow .learn-more {
    margin-top: 10px !important;
  }
  .largeimageTextbelow .btn-handler .date {
    margin-top: 10px;
  }
}
@media (min-width: 600px) and (max-width: 1148px) {
  .largeimageTextbelow .title2,
  .largeimageTextbelow .title1,
  .largeimageTextbelow .learn-more,
  .largeimageTextbelow .date {
    font-size: 18px;
  }
  .largeimageTextbelow .desc1,
  .largeimageTextbelow .desc2 {
    font-size: 13px;
  }
}

@media (min-width: 600px) and (max-width: 940px) {
  .largeimageTextbelow .title2,
  .largeimageTextbelow .title1,
  .largeimageTextbelow .learn-more,
  .largeimageTextbelow .date {
    font-size: 15px;
  }
  .largeimageTextbelow .desc1,
  .largeimageTextbelow .desc2 {
    font-size: 11px;
  }
}

@media (min-width: 600px) and (max-width: 783px) {
  .largeimageTextbelow .title2,
  .largeimageTextbelow .title1,
  .largeimageTextbelow .learn-more,
  .largeimageTextbelow .date {
    font-size: 11.5px;
  }
  .largeimageTextbelow .desc1,
  .largeimageTextbelow .desc2 {
    font-size: 10px;
  }
}

@media (max-width: 600px) {
  .largeimageTextbelow {
    text-align: center;
  }
  .largeimageTextbelow .title2,
  .largeimageTextbelow .title1,
  .largeimageTextbelow .learn-more,
  .largeimageTextbelow .date {
    font-size: 15px;
  }
  .largeimageTextbelow .desc1,
  .largeimageTextbelow .desc2 {
    font-size: 13px;
    margin-bottom: 20px;
  }
  .largeimageTextbelow .learn-more,
  .largeimageTextbelow .date {
    margin-bottom: 10px !important;
  }
}

@media (max-width: 474px) {
  .largeimageTextbelow .title2,
  .largeimageTextbelow .title1,
  .largeimageTextbelow .learn-more,
  .largeimageTextbelow .date {
    font-size: 12px;
  }
  .largeimageTextbelow .desc1,
  .largeimageTextbelow .desc2 {
    font-size: 10px;
  }
}

@media (max-width: 380px) {
  .largeimageTextbelow .title2,
  .largeimageTextbelow .title1,
  .largeimageTextbelow .learn-more,
  .largeimageTextbelow .date {
    font-size: 9px;
  }
  .largeimageTextbelow .desc1,
  .largeimageTextbelow .desc2 {
    font-size: 8px;
  }
}

.btn-handler {
  display: flex;
  justify-content: space-between;
}

.btn-handler .date {
  color: white;
  opacity: 50%;
}

/* @media (min-width: 600px) {
  .btn-handler .date {
    margin-top: 10px;
  }
} */
