@media (max-width: 450px) {
  .insurance-imgtext {
    margin-top: 50px;
  }
}

@media (max-width: 350px) {
  .insurance-imgtext {
    margin-top: 100px;
  }
}
