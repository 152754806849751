.ImgTextBelow .title1,
.ImgTextBelow .title2 {
  color: white;
  font-weight: 500;
  font-size: 17px;
}
.ImgTextBelow .title2 {
  color: var(--main-color);
  margin-bottom: 14px;
}
.ImgTextBelow .desc {
  color: white;
  opacity: 50%;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 14px;
}
.ImgTextBelow .learn-more {
  margin-top: 0px !important;
  opacity: 80%;
  margin-bottom: 10px;
}
@media (min-width: 600px) {
  .ImgTextBelow {
    margin-bottom: 30px;
  }
}
@media (min-width: 600px) and (max-width: 1074px) {
  .ImgTextBelow .title1,
  .ImgTextBelow .title2,
  .ImgTextBelow .learn-more,
  .ImgTextBelow .date {
    font-size: 15px;
  }
  .ImgTextBelow .desc {
    font-size: 13px;
  }
}

@media (min-width: 600px) and (max-width: 947px) {
  .ImgTextBelow .title1,
  .ImgTextBelow .title2 {
    font-size: 11px;
  }
  .ImgTextBelow .desc {
    font-size: 9px;
  }
  .ImgTextBelow .learn-more,
  .ImgTextBelow .date {
    font-size: 12px;
  }
}

@media (min-width: 600px) and (max-width: 700px) {
  .ImgTextBelow .title1,
  .ImgTextBelow .title2 {
    font-size: 9px;
  }
  .ImgTextBelow .desc {
    font-size: 8px;
  }
  .ImgTextBelow .learn-more,
  .ImgTextBelow .date {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .ImgTextBelow {
    text-align: center;
  }
  .ImgTextBelow .title1,
  .ImgTextBelow .title2 {
    font-size: 15px;
  }
  .ImgTextBelow .desc {
    font-size: 13px;
  }
}
@media (max-width: 474px) {
  .ImgTextBelow .title1,
  .ImgTextBelow .title2,
  .ImgTextBelow .learn-more,
  .ImgTextBelow .date {
    font-size: 12px;
  }
  .ImgTextBelow .desc {
    font-size: 10px;
  }
}

@media (max-width: 380px) {
  .ImgTextBelow .title1,
  .ImgTextBelow .title2,
  .ImgTextBelow .learn-more,
  .ImgTextBelow .date {
    font-size: 8.5px;
  }
  .ImgTextBelow .desc {
    font-size: 7.5px;
  }
}

@media (max-width: 430px) {
  .ImgTextBelow {
    margin-top: 50px;
  }
}
