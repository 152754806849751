.author-img {
  width: 20%;
  margin-right: 20px;
}
.author-title,
.author-name {
  color: white;
}
.author-title {
  font-size: 14px;
  opacity: 50%;
}
.author-name {
  font-size: 17px;
  font-weight: 500;
}
.author {
  display: flex;
  margin: 10px;
}
.sharearticle {
  color: white;
  font-size: 15px;
  opacity: 50%;
  text-align: end;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 0px;
}

.authorinfo {
  margin: 0px 1%;
}

.authorinfo .footer-col {
  text-align: center;
}
@media (max-width: 730px) {
  .authorinfo {
    margin: 0%;
  }
}

@media (max-width: 750px) {
  .author-name {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .author {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .sharearticle {
    /* justify-content: flex-start; */
    margin: 0px;
  }
}

/* // new area */
.author-info {
  display: flex;
  /* justify-content: space-around; */
}

@media (max-width: 600px) {
  .author-info {
    text-align: center;
    justify-content: center;
    margin-bottom: 15px;
  }
  .sharearticle {
    margin-bottom: 15px;
    font-size: 17px;
  }
  .author-info div {
    margin-top: 5%;
  }
}

@media (max-width: 350px) {
  .sharearticle {
    font-size: 14px;
  }
}
@media (min-width: 600px) and (max-width: 655px) {
  .sharearticle {
    font-size: 13px;
  }
}

.authorinfo .desc {
  color: white;
  opacity: 50%;
  font-size: 14px;
  margin: 5%;
}
