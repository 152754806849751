.imageText .title {
  color: white;
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 12px;
}
.imageText .desc {
  color: rgba(255, 255, 255, 0.507);
  /* max-width: 580px; */
  font-weight: 400;
  font-size: 14px;
  line-height: 1.6;
}

@media (min-width: 941px) and (max-width: 1067px) {
  .imageText .title {
    font-weight: 350;
    font-size: 20px;
    margin-bottom: 6px;
  }
  .imageText .desc {
    font-size: 13px;
    line-height: 1.5;
  }
}
@media (min-width: 847px) and (max-width: 940px) {
  .imageText .title {
    font-weight: 400;
    font-size: 18px;
    margin-bottom: 4px;
  }
  .imageText .desc {
    font-size: 12px;
    line-height: 1.4;
  }
}

@media (min-width: 716px) and (max-width: 846px) {
  .imageText .title {
    font-weight: 400;
    font-size: 16px;
  }
  .imageText .desc {
    font-size: 10px;
    line-height: 1.35;
  }
}
@media (min-width: 600px) and (max-width: 716px) {
  .imageText .title {
    font-weight: 400;
    font-size: 12px;
    margin-bottom: 3px;
  }
  .imageText .desc {
    font-size: 9px;
    line-height: 1.3;
  }
}

@media (min-width: 406px) and (max-width: 599px) {
  .imageText .title {
    font-weight: 400;
    font-size: 14px;
  }
  .imageText .desc {
    font-size: 10px;
    margin-bottom: 10px;
  }
}

@media (max-width: 405px) {
  .imageText .title {
    font-weight: 400;
    font-size: 10px;
    margin-bottom: 6px;
  }

  .imageText .desc {
    font-size: 9px;
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .imageText {
    text-align: center;
  }
}
