@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
body {
  line-height: 1.5;
  font-family: "Poppins", sans-serif;
}

.footer .container {
  margin: auto;
  text-align: center;
}
.row {
  display: flex;
  align-items: center;
}
ul {
  list-style: none;
}
.footer {
  padding-top: 80px;
  margin-bottom: 20px;
}

.footer-col.one {
  width: 25%;
  padding: 0 10px;
}
@media (max-width: 700px) {
  .footer-col {
    width: 100%;
  }
}
.footer-col.two {
  width: 50%;
  padding: 0px 10px;
}
.footer-col.three {
  width: 25%;
  padding: 0px 10px;
}

.footer-col.two ul {
  display: flex;
  justify-content: space-around;
}

.footer-col ul li:not(:last-child) {
  margin-bottom: 10px;
  position: relative;
}

.footer-col ul li a {
  font-size: 17px;
  text-transform: capitalize;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  transition: all 0.3s ease;
}

@media (min-width: 900px) and (max-width: 1030px) {
  .footer-col ul li a {
    font-size: 15px;
  }
}
.footer-col ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}
.footer-col .social-links a {
  display: inline-block;
  height: 40px;
  width: 40px;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 10px 10px 0;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footer-col .social-links a:hover {
  color: #24262b;
  background-color: #ffffff;
}

.footer-logo {
  height: 40px;
}
.footer-logo {
  cursor: pointer;
}

@media (max-width: 900px) {
  .row {
    flex-direction: column;
    justify-content: center;
  }
  .footer-col.one,
  .footer-col.two,
  .footer-col.three {
    width: 100%;
  }
  .footer-col.two ul {
    flex-direction: column;
  }
  .footer-logo {
    height: 50px;
  }
  .footer-col ul li {
    margin-bottom: 20px;
  }
}
