@media (min-width: 1101px) {
  .contact-grid {
    margin-top: -180px !important;
  }
}
@media (min-width: 600px) and (max-width: 1100px) {
  .contact-grid {
    margin-top: -130px !important;
  }
}
