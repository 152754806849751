@media (max-width: 680px) {
  .grr {
    margin-top: 40px !important;
  }
}
@media (max-width: 430px) {
  .grr {
    margin-top: 60px !important;
  }
}

@media (max-width: 370px) {
  .grr {
    margin-top: 80px !important;
  }
}

@media (max-width: 339px) {
  .grr {
    margin-top: 115px !important;
  }
}
