body {
  background-color: black;
}
.textimgimg {
  overflow-x: hidden;
}
.textimgimg .desc {
  color: rgba(255, 255, 255, 0.507);
  font-weight: 400;
  font-size: 14px;
  max-width: 465px;
}
.textimgimg .title1 {
  color: white;
  font-size: 22px;
  font-weight: 500;
}
.textimgimg .title2 {
  font-size: 22px;
  font-weight: 500;
  color: var(--main-color);
  margin-bottom: 12px;
}
.img-cont {
  display: flex;
  justify-content: center;
}
.textimgimg .img-cont :first-child {
  margin-right: 10px;
}

@media (max-width: 1158px) {
  .textimgimg img {
    width: 50%;
  }
}
@media (max-width: 405px) {
  .textimgimg .title1,
  .textimgimg .title2 {
    font-size: 10px;
  }
  .textimgimg .title2 {
    margin-bottom: 10px;
  }
  .textimgimg .desc {
    font-size: 9px;
  }
}

@media (min-width: 406px) and (max-width: 599px) {
  .textimgimg .title1,
  .textimgimg .title2 {
    font-size: 14px;
  }
  .textimgimg .title2 {
    margin-bottom: 10px;
  }
  .textimgimg .desc {
    font-size: 10px;
  }
}

@media (min-width: 600px) and (max-width: 846px) {
  .textimgimg .title1,
  .textimgimg .title2 {
    font-size: 12px !important;
  }
  .textimgimg .title2 {
    margin-bottom: 4px;
  }
  .textimgimg .desc {
    font-size: 10px;
  }
}

@media (min-width: 847px) and (max-width: 940px) {
  .textimgimg .title1,
  .textimgimg .title2 {
    font-size: 16px !important;
  }
  .textimgimg .title2 {
    margin-bottom: 4px;
  }
}

@media (min-width: 941px) and (max-width: 1080px) {
  .textimgimg .title1,
  .textimgimg .title2 {
    font-size: 17px !important;
  }
  .textimgimg .desc {
    font-size: 13px;
  }
  .textimgimg .title2 {
    margin-bottom: 6px;
  }
}

@media (max-width: 600px) {
  .textimgimg .desc {
    margin-bottom: 12px;
  }
  .textimgimg {
    margin-bottom: 50px;
    text-align: center;
  }
}
