.smallimgtext .title {
  color: var(--main-color);
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 500;
}
.smallimgtext .desc {
  color: white;
  opacity: 50%;
  font-size: 16px;
  font-weight: 400;
}
@media (min-width: 1280px) {
  .smallimgtext .title {
    margin-left: 70px;
  }
}
@media (max-width: 960px) {
  .smallimgtext {
    text-align: center;
  }
}
