.slider-section {
  overflow-x: hidden;
  font-family: "Inter", sans-serif;
}
.slider {
  position: relative;
  background-color: black;
}

.slide {
  width: 100%;
  position: relative;
  opacity: 0;
  transition-duration: 1s ease;
}
.slide.active {
  opacity: 1;
  transition-duration: 1s;
}
.img-slider {
  width: 100%;
  height: 100%;
}

/* Start content-container */
.content-container {
  position: absolute;
  top: 30%;
  left: 5%;
}
.content-container .slideNb {
  color: white;
  opacity: 0.55;
  font-size: 35px;
}

.content-container .title1 {
  font-size: 45px;
  letter-spacing: 0.2px;
  font-weight: 400;
  color: white;
}
.content-container .title2 {
  font-size: 45px;
  letter-spacing: 0.2px;
  color: var(--main-color);
}

.content-container .desc {
  font-size: 17px;
  margin-top: 10px;
  max-width: 600px;
  opacity: 0.55;
  color: white;
}
.learn-more {
  background-color: transparent;
  border: none;
  color: white;
  margin-top: 25px;
  text-decoration: underline;
  font-size: 17px;
}
.learn-more:hover {
  cursor: pointer;
}
@media (max-width: 790px) {
  .content-container {
    top: 35%;
  }
  .content-container .slideNb {
    font-size: 20px;
  }
  .content-container .title1 {
    font-size: 25px;
  }
  .content-container .title2 {
    font-size: 25px;
  }
  .content-container .desc {
    font-size: 13px;
    max-width: 500px;
  }
  .content-container .learn-more {
    font-size: 14px;
  }
}
@media (min-width: 340px) and (max-width: 550px) {
  .content-container {
    top: 20% !important;
  }
}
@media (max-width: 445px) {
  .content-container {
    top: 35%;
  }
  .content-container .slideNb {
    font-size: 15px;
  }
  .content-container .title1 {
    font-size: 20px;
  }
  .content-container .title2 {
    font-size: 20px;
  }
  .content-container .desc {
    font-size: 11px;
    max-width: 500px;
  }
  .content-container .learn-more {
    font-size: 14px;
    margin-top: 2px;
  }
}
@media (max-width: 343px) {
  .content-container {
    top: 30%;
  }
  .content-container .slideNb {
    font-size: 12px;
  }
  .content-container .title1 {
    font-size: 15px;
  }
  .content-container .title2 {
    font-size: 15px;
  }
  .content-container .desc {
    font-size: 8px;
    max-width: 500px;
  }
  .content-container .learn-more {
    font-size: 11px;
    margin-top: 2px;
  }
}
@media (min-width: 768px) {
  .content-container {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .content-container {
    width: 970px;
  }
}
@media (min-width: 1200px) {
  .content-container {
    width: 1170px;
  }
}
.slideshowDots-container {
  background-color: rgba(0, 0, 0.1);
  margin-top: -0.1%;
}
.slideshowDots {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(140px, auto));
  gap: 15px;
  padding: 20px 0px;
  margin-left: 5%;
  background-color: black;
  margin-bottom: 50px;
}

@media (min-width: 487px) {
  .slideshowDots div:nth-child(3) {
    margin-left: 30px;
  }
}

.dots-container {
  cursor: pointer;
}
.dots-container p {
  color: rgba(255, 255, 255, 0.363);
  font-size: 12px;
  width: 150px;
  font-weight: 450;
}
.dots-container.active p {
  color: white;
}
.dots-container.active .slideNb {
  color: var(--main-color);
}

.arrows-container {
  display: flex;
  align-items: center;
}
.right-arrow {
  position: absolute;
  right: 32px;
  font-size: 24px;
  color: white;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.left-arrow {
  position: absolute;
  right: 80px;
  font-size: 24px;
  color: white;
  z-index: 10;
  cursor: pointer;
}
@media (max-width: 320px) {
  .slideshowDots-container {
    margin-top: 50px;
  }
}
@media (min-width: 344px) {
  .slideshowDots-container {
    margin-top: 30px;
  }
}

@media (min-width: 446px) {
  .slideshowDots-container {
    margin-top: 40px;
  }
}

.left-arrow,
.right-arrow {
  width: 24px;
}
