@media (min-width: 601px) {
  .largeimageText {
    margin-top: -100px;
  }
}

.largeimageText .title {
  color: white;
  font-size: 22px;
  margin-bottom: 25px;
}
.largeimageText .desc1,
.largeimageText .desc2 {
  color: white;
  opacity: 50%;
  font-size: 14px;
  font-weight: 250;
}
.largeimageText .desc2 {
  margin-top: 20px;
}

@media (max-width: 600px) {
  .largeimageText {
    text-align: center;
  }
}

@media (max-width: 450px) {
  .largeimageText .title {
    font-size: 17px;
  }
  .largeimageText .desc1,
  .largeimageText .desc2 {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .largeimageText .title {
    font-size: 15px;
  }
  .largeimageText .desc1,
  .largeimageText .desc2 {
    font-size: 12px;
  }
}
