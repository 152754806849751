@media (max-width: 750px) {
  .realstate-imgtext {
    margin-top: 50px;
  }
}

@media (min-width: 600px) and (max-width: 620px) {
  .realstate-imgtext {
    margin-top: 100px;
  }
}
