.smalltextimg .title {
  color: var(--main-color);
  margin-bottom: 10px;
  font-size: 25px;
  font-weight: 500;
}
.smalltextimg .desc {
  color: white;
  opacity: 50%;
  font-size: 15px;
  font-weight: 400;
}
@media (max-width: 960px) {
  .smalltextimg {
    text-align: center;
  }
}

@media (max-width: 600px) {
  .smalltextimg {
    margin-bottom: 100px;
  }
}
