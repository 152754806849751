.ContactForm {
  backdrop-filter: blur(30px);
  /* margin-top: 100px; */
  padding: 20px;
}

@media (min-width: 600px) {
  .ContactForm {
    height: 580px;
  }
}
.f-dropSms {
  color: white;
  font-size: 14px;
  text-align: center;
  display: flex;
  align-items: center;
}
.f-clearAll {
  font-size: 10px;
  color: white;
  opacity: 50%;
  align-items: center;
}

.f-clearImg img {
  display: flex;
  align-items: center;
}

@media (min-width: 600px) and (max-width: 700px) {
  .f-dropSms {
    font-size: 7px;
    text-align: center;
    /* margin-left: 5px; */
  }
  .f-clearAll {
    font-size: 8px;
    color: white;
    opacity: 50%;
  }
  .f-clearImg img {
    width: 15px;
    display: flex;
    align-items: center;
  }
}

@media (min-width: 701px) and (max-width: 800px) {
  .f-dropSms {
    font-size: 9px;
    text-align: center;
    /* margin-left: 5px; */
  }
  .f-clearAll {
    font-size: 9px;
    color: white;
    opacity: 50%;
  }
  .f-clearImg img {
    width: 25px;
    display: flex;
    align-items: center;
  }
}
@media (min-width: 801px) and (max-width: 960px) {
  .f-dropSms {
    font-size: 12px;
    text-align: center;
    /* margin-left: 5px; */
  }
  .f-clearAll {
    font-size: 11px;
    color: white;
    opacity: 50%;
    text-align: end;
  }
  .f-clearImg img {
    width: 25px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .f-dropSms {
    font-size: 11px;
  }
  .f-clearAll {
    font-size: 11px;
    color: white;
    opacity: 50%;
    text-align: end;
  }
  .f-clearImg img {
    width: 25px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 366px) {
  .f-dropSms {
    font-size: 8px;
  }
  .f-clearAll {
    font-size: 8px;
    color: white;
    opacity: 50%;
    text-align: end;
  }
  .f-clearImg img {
    width: 15px;
    display: flex;
    align-items: center;
  }
}

@media (max-width: 300px) {
  .f-dropSms {
    font-size: 8px;
  }
  .f-clearAll {
    font-size: 8px;
    color: white;
    opacity: 50%;
    text-align: end;
  }
  .f-clearImg img {
    width: 15px;
    display: flex;
    align-items: center;
  }
}

.button-reset {
  background-color: transparent;
  border: none;
  color: whitesmoke;
}
@media (max-width: 800px) {
  .button-reset {
    font-size: 10px;
  }
}
@media (min-width: 600px) and (max-width: 660px) {
  .button-reset {
    font-size: 8px;
  }
}
.submit-form {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 18px;
  text-decoration: underline;
  text-decoration-color: white;
  cursor: pointer;
}
@media (max-width: 600px) {
  .submit-form {
    font-size: 12px;
  }
}
.button-reset-b {
  background-color: transparent;
  color: white;
  border: none;
}
@media (min-width: 600px) {
  .button-reset-b {
    font-size: 14px;
  }
}
