* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  scroll-behavior: smooth;
}
header {
  font-family: "Questrial", sans-serif;
}
ul {
  list-style: none;
}
.container {
  padding-left: 15px;
  padding-right: 15px;
  margin-left: auto;
  margin-right: auto;
}
header {
  position: absolute;
  left: 0px;
  width: 100%;
  z-index: 2;
}
header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-top: 5px;
  margin-right: auto;
  margin-left: 5%;
}

@media (max-width: 570px) {
  header .container {
    margin-left: 3%;
  }
}

header .container .logo {
  padding-top: 5px;
  margin-left: -20px;
}

header .container .logo img {
  background-color: transparent;
  height: 50px;
}

@media (max-width: 380px) {
  header .container .logo img {
    height: 28px !important;
  }
}

header nav {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
header nav .toggle-menu {
  color: #fbfbfb;
  font-size: 30px;
  margin-right: 6%;
}
@media (min-width: 991px) {
  header nav .toggle-menu {
    display: none;
  }
}
header nav .nav-menu {
  display: flex;
}
@media (max-width: 991px) {
  header nav .nav-menu {
    display: none;
  }
  header nav .activeee {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 20px;
    top: 100%;
    left: 25%;
    width: 50%;
    z-index: 999;
    background-color: var(--main-color);
    background-image: linear-gradient(322deg, #84ffbd -120%, #000000 74%);
    box-shadow: 0px 0px 13px -6px var(--main-color);
    border-radius: 5px;
  }
  header nav .activeee li a {
    /* color: black !important; */
    transition: 0.3s;
  }
  header nav .activeee li a:hover {
    padding-left: 15px !important;
  }
  header .container .logo img {
    height: 35px;
  }
}

header nav .nav-menu li a {
  text-decoration: none;
  list-style: none;
  display: block;
  color: white;
  opacity: 0.9;
  font-size: 15px;
  transition: 0.5;
  padding: 10px 10px;
}

header nav .nav-menu li a:hover {
  opacity: 0.7;
}

.active {
  color: #85ffbd !important;
}
