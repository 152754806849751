/* .slider-home{
    height: 1070px;
}


@media (max-width: 310px) {
    .slider-home {
        height: 600px;
    }
}

@media (min-width: 310px) {
    .slider-home {
        height: 500px;
    }
}

@media (min-width: 550px) {
    .slider-home {
        height: 600px;
    }
}

@media (min-width: 850px) {
    .slider-home {
        height: 700px;
    }
}

@media (min-width: 1025px) {
    .slider-home {
        height: 800px;
    }
}

@media (min-width: 1200px) {
    .slider-home {
        height: 900px;
    }
}

@media (min-width: 1350px) {
    .slider-home {
        height: 1000px;
    }
}

@media (min-width: 1500px) {
    .slider-home {
        height: 1100px;
    }
}

@media (min-width: 1600px) {
    .slider-home {
        height: 1200px;
    }
}

@media (min-width: 1850px) {
    .slider-home {
        height: 1250px;
    }
}

@media (min-width: 1900px) {
    .slider-home {
        height: 1300px;
    }
}

@media (min-width: 2000px) {
    .slider-home {
        height: 1400px;
    }
}

@media (min-width: 2200px) {
    .slider-home {
        height: 1500px;
    }
}

@media (min-width: 2400px) {
    .slider-home {
        height: 1600px;
    }
}

@media (min-width: 2600px) {
    .slider-home {
        height: 1700px;
    }
}

@media (min-width: 2800px) {
    .slider-home {
        height: 1800px;
    }
}

@media (min-width: 3000px) {
    .slider-home {
        height: 2000px;
    }
} */

.slider {
  height: 600px;
}
@media (max-width: 310px) {
  .slider {
    height: 100px;
  }
}
@media (min-width: 310px) {
  .slider {
    height: 200px;
  }
}
@media (min-width: 450px) {
  .slider {
    height: 250px;
  }
}
@media (min-width: 550px) {
  .slider {
    height: 300px;
  }
}
@media (min-width: 640px) {
  .slider {
    height: 350px;
  }
}
@media (min-width: 740px) {
  .slider {
    height: 400px;
  }
}
@media (min-width: 840px) {
  .slider {
    height: 500px;
  }
}
@media (min-width: 1025px) {
  .slider {
    height: 550px;
  }
}
@media (min-width: 1100px) {
  .slider {
    height: 600px;
  }
}
@media (min-width: 1200px) {
  .slider {
    height: 650px;
  }
}
@media (min-width: 1280px) {
  .slider {
    height: 700px;
  }
}
@media (min-width: 1360px) {
  .slider {
    height: 720px;
  }
}

@media (min-width: 1420px) {
  .slider {
    height: 800px;
  }
}

@media (min-width: 1550px) {
  .slider {
    height: 900px;
  }
}
@media (min-width: 1730px) {
  .slider {
    height: 1000px;
  }
}

@media (min-width: 1850px) {
  .slider {
    height: 1050px;
  }
}

@media (min-width: 2000px) {
  .slider {
    height: 1100px;
  }
}

@media (min-width: 2100px) {
  .slider {
    height: 1200px;
  }
}

@media (min-width: 2300px) {
  .slider {
    height: 1300px;
  }
}

@media (min-width: 2500px) {
  .slider {
    height: 1400px;
  }
}

@media (min-width: 2700px) {
  .slider {
    height: 1500px;
  }
}
@media (min-width: 2850px) {
  .slider {
    height: 1600px;
  }
}

@media (min-width: 3000px) {
  .slider {
    height: 1600px;
  }
}
